import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/template-parts/hero"

import '../scss/blog.scss'

const BlogIndex = ({ data, pageContext: { nextPagePath, previousPagePath } }) => {
	const posts = data.allWpPost.nodes
	const page = {
		hero: {
			backgroundImage: null,
			title: 'In the news',
			content: null,
			largeHero: null
		}
	}

	return (
		<Layout isHomePage>
			<Seo title="In the news" />
			<Hero page={page} />
			<section className="posts">
				<div className="container">
					<div className="posts-grid">
						{posts && posts.length ? posts.map(post => {
							const postLink = ( post?.blogPost?.outsideLink?.url ) ? post?.blogPost?.outsideLink?.url : post.uri
							const linkTarget = ( post?.blogPost?.outsideLink?.url ) ? '_blank' : '_self'
							const image = post && post.featuredImage && post.featuredImage.image && post.featuredImage.image.localFile && getImage(post.featuredImage.image.localFile)
							return (
								<article className="post-list-item" itemScope itemType="http://schema.org/Article" key={post.id}>
									<div className="image">
										{image && (
											<Link to={postLink} itemProp="url"  target={linkTarget}>
												<div className="overlay"></div>
												<GatsbyImage style={{gridArea: "1/1"}} layout={'constrained'} image={image} alt={post.title} />
											</Link>
										)}
									</div>
									<div className="content">
										<h2>
											<Link to={postLink} itemProp="url" target={linkTarget}>
												<span itemProp="headline">{parse(post.title)}</span>
											</Link>
										</h2>
										<small>{post.author.node.name} | {post.date}</small>
										<section itemProp="description">{parse(post.excerpt)}</section>
									</div>
								</article>
							)
						}) : (
							<p>
								No posts found.
							</p>
						)}
					</div>
				</div>
			</section>
			<section className="pagination">
				<div className="container">
					{nextPagePath ? <Link to={nextPagePath} className="older"><span>&laquo;</span> Older</Link> : <span />}
					{previousPagePath ? <Link to={previousPagePath} className="newer">Newer <span>&raquo;</span></Link> : <span />}
				</div>
			</section>
		</Layout>
	)
}

export default BlogIndex

export const pageQuery = graphql`
query WordPressNewsPostArchive($offset: Int!, $postsPerPage: Int!) {
	allWpPost(
	sort: { fields: [date], order: DESC }
	limit: $postsPerPage
	skip: $offset
	filter: {categories: {nodes: {elemMatch: {slug: {in: ["news", "press"]}}}}}
	) {
		nodes {
			id
			excerpt
			uri
			date(formatString: "MMMM DD, YYYY")
			title
			excerpt
			categories {
				nodes {
					slug
				}
			}
			author {
				node {
					name
				}
			}
			featuredImage {
				image: node {
					localFile {
						childImageSharp {
							gatsbyImageData(
							layout: CONSTRAINED
							width: 670
							height: 443
							placeholder: BLURRED
							formats: [AUTO]
							)
						}
					}
				}
			}
			blogPost {
				outsideLink {
					url
				}
			}
		}
	}
}
`
